/**
 * CSS for laying out and displaying blocks and boxes
 */
:root {

    /* Consistent paddings */

    /* shadows */

    /* All z-index values should be listed here */

    /* widths */
}
/**
 * CSS for colors
 */
/* stylelint-disable color-no-hex */
:root {
    /* TIER ONE - DESCRIPTIVE NAMES */

    /* Default / Text color + Light background / Background color + Light text */        /* dark orange */          /* brand blue */           /* red */        /* purple */         /* dark green */          /* gray */            /* white */        /* yellow */

    /* Highlight / Text color + Dark background / Background color + Dark text */                                /* orange */            /* light red #f0425f */      /* light purple #ce51e1 */                                 /* bright green */                                  /* bright blue - brand color */                                /* light yellow */

    /* Alerts */

    /* Additional blues */         /* dark blue #003366 */  /* medium blue #0066cc */ /* light blue #c7e3ff */  /* light blue #e5f2ff */

    /* Additional reds */  /* light red #fbd0d7 */  /* dark red #8E0B21 */

    /* Additional greens */                                 /* dark green */ /* light green #e5fff1 */

    /* Additional purples */    /* extra light purple #f3d3f8 */       /* very light purple #f3d3f840 */

    /* Additional oranges */    /* light orange #ffe8cc */

    /* Grayscale Colors */  /* Near black */ /* Dark gray #1d2830 */  /* Formerly graphite */       /* medium gray */ /* #b1b2b4 */        /* #d8d9da */  /* Near white #f2f2f3 */

    /* TIER TWO - SEMANTIC NAMES */

    /* Product Colors */

    /* Component Colors */

    /* Partner/Refi Lender Colors */

    /* First Republic palette */    /* forest green */        /* gold hacienda */        /* tan gray concrete */      /* dark gray matterhorn */

    /* Visualization palette */

    /* Marketing Page Colors */ /* #1d2830; */ /* #005cb9; *//* #0f1519; */ /* #1d2830; */ /* #5a666e; *//* #8a8c8f; */ /* #b1b2b4; */ /* #d7d8d9; *//* #f2f2f3; */ /* #e5f2ff; */ /* #eddef0; */
}
/* this section is deprecated - prefer to use device-specific names if possible */
/* device based size names */
/* iPhone 5 width: 20em */
/* iPhone 6 width: */
/* iPhone 5 height: 35.5em */
/* iPhone 6 plus height: 46em */
/* iPad width: 48em */
/* iPad height: 64em */
.desktop {
    display: block;
}
.mobile {
    display: none;
}
@media (max-width: 30em) {
    .desktop {
        display: none;
    }

    .mobile {
        display: block;
    }
}
/**
 * CSS for text, titles, labels. word.
 */
/* Marketing Text Mixins */
.menu-overlay {
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 68;
    background-color: rgba(29,41,52, 0.7);
}
.page-header .mobile-menu-item {
    min-width: 280px;
}
.page-header .mobile-menu-item .mobile-menu-container {
        width: 100%;
    }
.page-header .mobile-menu-item .mobile-header-container,
    .page-header .mobile-menu-item .mobile-header-container-marketing {
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -webkit-flex-flow: row wrap;
           -moz-box-orient: horizontal;
           -moz-box-direction: normal;
            -ms-flex-flow: row wrap;
                flex-flow: row wrap;
        -webkit-box-pack: justify;
        -webkit-justify-content: space-between;
           -moz-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between;
        width: 100%;
        padding: 0 8px;
        height: 64px;
        background-color: rgb(29, 40, 48);
        z-index: 71;
    }
.page-header .mobile-menu-item .mobile-header-container .mobile-buttons-section, .page-header .mobile-menu-item .mobile-header-container-marketing .mobile-buttons-section {
            display: -webkit-box;
            display: -webkit-flex;
            display: -moz-box;
            display: -ms-flexbox;
            display: flex;
        }
.page-header .mobile-menu-item .mobile-header-container .mobile-buttons-section .spacer, .page-header .mobile-menu-item .mobile-header-container-marketing .mobile-buttons-section .spacer {
                border-right: 1px solid #5a666e;
                margin: 16px;
                width: 2px;
            }
.page-header .mobile-menu-item .mobile-header-container .mobile-buttons-section .btn-demo, .page-header .mobile-menu-item .mobile-header-container-marketing .mobile-buttons-section .btn-demo {
                text-align: center;
                -webkit-align-self: center;
                    -ms-flex-item-align: center;
                            -ms-grid-row-align: center;
                        align-self: center;
            }
.page-header .mobile-menu-item .mobile-header-container-marketing {
        background-color: white;
    }
.page-header .mobile-menu-item .site-logo-container {
        top: 8px;
        width: 352px;
        height: 48px;
        margin: 4px 0 0 8px;
        display: inline-block;
    }
.page-header .mobile-menu-item .site-logo-container .site-logo-marketing {
            background: url("../../assets/Gradifi-Logo-Horiz-Color.svg") left center no-repeat;
            background-size: contain;
            width: 100%;
            height: 40px;
            display: inline-block;
        }
.page-header .mobile-menu-item .site-logo-container .site-logo {
            background: url("../../assets/Gradifi-Logo-Horiz-White.svg") left center no-repeat;
            background-size: contain;
            width: 100%;
            height: 40px;
            display: inline-block;
        }
.page-header .mobile-menu-item .sub-item-container {
        min-width: auto;
        height: 100vh;
    }
.page-header .mobile-menu-item .sub-item-container .sub-items {
            width: 100%;
        }
.page-header .mobile-menu-item .sub-item-container .sub-items .logo-item {
                display: none;
            }
.page-header .mobile-menu-item .sub-item-container .sub-items .left-section {
                padding-top: 32px;
                padding-left: 32px;
            }
.page-header .mobile-menu-item .sub-item-container .sub-items .left-section > .header-item {
                    height: 52px;
                    padding-bottom: 32px;
                }
.page-header .mobile-menu-item .sub-item-container .sub-items .left-section,
            .page-header .mobile-menu-item .sub-item-container .sub-items .right-section {
                display: block;
            }
.page-header .mobile-menu-item .sub-item-container .sub-items .labelled-item {
                margin-bottom: 8px;
            }
.page-header .mobile-menu-item .sub-item-container .sub-items .right-section .header-item:first-of-type .label-link {
                    -webkit-box-align: baseline;
                    -webkit-align-items: baseline;
                       -moz-box-align: baseline;
                        -ms-flex-align: baseline;
                            align-items: baseline;
                    padding-top: 0;
                }
.page-header .mobile-menu-item .sub-item-container .sub-items .right-section .labelled-item {
                    height: 54px;
                    padding-left: 60px;
                }
.page-header .mobile-menu-item .sub-item-container .sub-items .right-section .labelled-item .label-link {
                        padding-left: 4px;
                    }
.page-header .mobile-menu-item .sub-item-container .sub-items .right-section .user-item {
                    -webkit-box-align: start;
                    -webkit-align-items: flex-start;
                       -moz-box-align: start;
                        -ms-flex-align: start;
                            align-items: flex-start;
                    -webkit-box-orient: vertical;
                    -webkit-box-direction: normal;
                    -webkit-flex-direction: column;
                       -moz-box-orient: vertical;
                       -moz-box-direction: normal;
                        -ms-flex-direction: column;
                            flex-direction: column;
                    padding: 0 0 16px;
                    margin-top: 32px;
                }
.page-header .mobile-menu-item .sub-item-container .sub-items .right-section .user-item .user-item-button {
                        width: auto;
                        margin-bottom: 16px;
                        padding: 8px 0 8px 24px;
                        font-size: 1rem;
                        font-weight: 600;
                        height: 52px;
                    }
.page-header .mobile-menu-item .sub-item-container .sub-items .right-section .user-item .user-item-button .icon-down-open {
                            display: none;
                        }
.page-header .mobile-menu-item .sub-item-container .sub-items .right-section .user-item .user-item-button + .sub-item-container .header-item:not(:first-of-type) .label-link {
                            padding-top: 0;
                            -webkit-box-align: baseline;
                            -webkit-align-items: baseline;
                               -moz-box-align: baseline;
                                -ms-flex-align: baseline;
                                    align-items: baseline;
                        }
.page-header .mobile-menu-item .sub-item-container .sub-items .right-section .user-item .sub-item-container {
                        padding: 0;
                    }
.page-header .mobile-menu-item .sub-item-container .sub-items .right-section .user-item .sub-items {
                        padding-left: 4px;
                    }
.page-header .mobile-menu-item .sub-item-container .sub-items .header-item {
                -webkit-flex-wrap: wrap;
                    -ms-flex-wrap: wrap;
                        flex-wrap: wrap;
                -webkit-box-pack: start;
                -webkit-justify-content: flex-start;
                   -moz-box-pack: start;
                    -ms-flex-pack: start;
                        justify-content: flex-start;
                padding: 0 24px;
                margin-bottom: 0;
            }
.page-header .mobile-menu-item .sub-item-container .sub-items .header-item .label-link {
                    width: auto;
                    margin-left: 4px;
                    font-size: 1rem;
                    font-weight: 600;
                }
.page-header .mobile-menu-item .sub-item-container .sub-items .header-item .label-link i {
                        margin-right: 16px;
                    }
.page-header .mobile-menu-item .sub-item-container .payment-accounts + .header-item {
                margin-top: 4px;
            }
.page-header .mobile-menu-item .sub-item-container .payment-accounts > .header-item {
                -webkit-box-align: start;
                -webkit-align-items: flex-start;
                   -moz-box-align: start;
                    -ms-flex-align: start;
                        align-items: flex-start;
                margin-left: 32px;
                padding-bottom: 0;
            }
.page-header .mobile-menu-item .sub-item-container .payment-accounts > .header-item > .sub-item-container {
                    padding-top: 0;
                }
.page-header .mobile-menu-item .sub-item-container .payment-accounts > .header-item > .sub-item-container > .header-item {
                        height: 52px;
                        margin-bottom: 2px;
                    }
.page-header .mobile-menu-item .sub-item-container .payment-accounts .header-item {
                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                -webkit-flex-direction: column;
                   -moz-box-orient: vertical;
                   -moz-box-direction: normal;
                    -ms-flex-direction: column;
                        flex-direction: column;
                padding: 0;
                right: 4px;
            }
.page-header .mobile-menu-item .sub-item-container .payment-accounts .header-item:first-of-type {
                    padding-bottom: 24px;
                }
.page-header .mobile-menu-item .sub-item-container .payment-accounts .header-item:first-of-type i {
                        margin-right: 16px;
                    }
.page-header .mobile-menu-item .sub-item-container .payment-accounts .header-item:not(:first-of-type) {
                    -webkit-box-pack: center;
                    -webkit-justify-content: center;
                       -moz-box-pack: center;
                        -ms-flex-pack: center;
                            justify-content: center;
                }
.page-header .mobile-menu-item .sub-item-container .payment-accounts .header-item:not(:first-of-type) .label-link {
                        padding-top: 0;
                        -webkit-box-align: baseline;
                        -webkit-align-items: baseline;
                           -moz-box-align: baseline;
                            -ms-flex-align: baseline;
                                align-items: baseline;
                        margin: 0;
                        right: 2px;
                    }
.page-header .mobile-menu-item .sub-item-container .payment-accounts .account-dropdown {
                display: none;
            }
.page-header .mobile-menu-item .sub-item-container .payment-accounts .sub-item-container {
                height: auto;
            }
.page-header .mobile-menu-item .sub-item-container .payment-accounts .sub-item-container .icon-bank {
                    display: block;
                }
/* further nesting in mobile header should always be maximized */
.page-header .mobile-menu-item .sub-item-container .sub-item-container {
            position: relative;
        }
.page-header .mobile-menu-item .sub-item-container .sub-item-container .sub-items {
                position: relative;
            }
.page-header .mobile-menu-item .menu-toggle,
    .page-header .mobile-menu-item .menu-button::after,
    .page-header .mobile-menu-item .menu-button::before {
        height: 4px;
        width: 32px;
        background: white;
        border-radius: 8px;
    }
.page-header .mobile-menu-item .menu-toggle-marketing,
    .page-header .mobile-menu-item .menu-button-marketing::after,
    .page-header .mobile-menu-item .menu-button-marketing::before {
        height: 4px;
        width: 32px;
        background: #005cb9;
        border-radius: 8px;
    }
.page-header .mobile-menu-item .menu-button,
    .page-header .mobile-menu-item .menu-button-marketing {
        cursor: pointer;
        margin-right: 4px;
        margin-top: 4px;
        height: 36px;
        top: 8px;
        bottom: 8px;
        border: none;
        outline: none;
    }
.page-header .mobile-menu-item .menu-button .menu-toggle,
        .page-header .mobile-menu-item .menu-button .menu-toggle-marketing,
        .page-header .mobile-menu-item .menu-button-marketing .menu-toggle,
        .page-header .mobile-menu-item .menu-button-marketing .menu-toggle-marketing {
            top: 16px;
            -webkit-transition: -webkit-transform 250ms ease 0ms;
            transition: -webkit-transform 250ms ease 0ms;
            -o-transition: transform 250ms ease 0ms;
            transition: transform 250ms ease 0ms;
            transition: transform 250ms ease 0ms, -webkit-transform 250ms ease 0ms;
        }
.page-header .mobile-menu-item .menu-button::after,
        .page-header .mobile-menu-item .menu-button::before,
        .page-header .mobile-menu-item .menu-button-marketing::after,
        .page-header .mobile-menu-item .menu-button-marketing::before {
            content: "";
            position: absolute;
            -webkit-transition: top 250ms ease 250ms, -webkit-transform 250ms ease 0ms;
            transition: top 250ms ease 250ms, -webkit-transform 250ms ease 0ms;
            -o-transition: top 250ms ease 250ms, transform 250ms ease 0ms;
            transition: top 250ms ease 250ms, transform 250ms ease 0ms;
            transition: top 250ms ease 250ms, transform 250ms ease 0ms, -webkit-transform 250ms ease 0ms;
        }
.page-header .mobile-menu-item .menu-button::before, .page-header .mobile-menu-item .menu-button-marketing::before {
            top: 8px;
        }
.page-header .mobile-menu-item .menu-button::after, .page-header .mobile-menu-item .menu-button-marketing::after {
            top: 24px;
        }
.page-header .mobile-menu-item.menu-open .menu-button .menu-toggle, .page-header .mobile-menu-item.menu-open .menu-button-marketing .menu-toggle {
            -webkit-transform: rotateZ(45deg);
                -ms-transform: rotate(45deg);
                    transform: rotateZ(45deg);
        }
.page-header .mobile-menu-item.menu-open .menu-button .menu-toggle-marketing, .page-header .mobile-menu-item.menu-open .menu-button-marketing .menu-toggle-marketing {
            -webkit-transform: rotateZ(45deg);
                -ms-transform: rotate(45deg);
                    transform: rotateZ(45deg);
        }
.page-header .mobile-menu-item.menu-open .menu-button::before, .page-header .mobile-menu-item.menu-open .menu-button-marketing::before {
            top: 16px;
            -webkit-transform: rotateZ(45deg);
                -ms-transform: rotate(45deg);
                    transform: rotateZ(45deg);
        }
.page-header .mobile-menu-item.menu-open .menu-button::after, .page-header .mobile-menu-item.menu-open .menu-button-marketing::after {
            top: 16px;
            -webkit-transform: rotateZ(-45deg);
                -ms-transform: rotate(-45deg);
                    transform: rotateZ(-45deg);
        }
.page-header .mobile-menu-item.menu-open .menu-button .menu-toggle,
        .page-header .mobile-menu-item.menu-open .menu-button .menu-toggle-marketing,
        .page-header .mobile-menu-item.menu-open .menu-button::before,
        .page-header .mobile-menu-item.menu-open .menu-button::after,
        .page-header .mobile-menu-item.menu-open .menu-button-marketing .menu-toggle,
        .page-header .mobile-menu-item.menu-open .menu-button-marketing .menu-toggle-marketing,
        .page-header .mobile-menu-item.menu-open .menu-button-marketing::before,
        .page-header .mobile-menu-item.menu-open .menu-button-marketing::after {
            -webkit-transition: top 250ms ease 0ms, -webkit-transform 250ms ease 250ms;
            transition: top 250ms ease 0ms, -webkit-transform 250ms ease 250ms;
            -o-transition: top 250ms ease 0ms, transform 250ms ease 250ms;
            transition: top 250ms ease 0ms, transform 250ms ease 250ms;
            transition: top 250ms ease 0ms, transform 250ms ease 250ms, -webkit-transform 250ms ease 250ms;
        }
@media (max-width: 40em) {
        .page-header .mobile-menu-item .mobile-buttons-section .btn-demo,
        .page-header .mobile-menu-item .spacer {
            display: none;
        }

        .page-header .mobile-menu-item .mobile-header-container .menu {
            margin-right: 24px;
            top: 4px;
        }

            .page-header .mobile-menu-item .mobile-header-container .menu::before {
                top: 6px;
            }

        .page-header .mobile-menu-item.menu-open .mobile-menu-container .mobile-header-container .menu::before {
            top: 16px;
        }

        .page-header .mobile-menu-item .site-logo-container {
            width: 240px;
        }
}
