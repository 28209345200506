/* this section is deprecated - prefer to use device-specific names if possible */
/* device based size names */
/* iPhone 5 width: 20em */
/* iPhone 6 width: */
/* iPhone 5 height: 35.5em */
/* iPhone 6 plus height: 46em */
/* iPad width: 48em */
/* iPad height: 64em */
.desktop {
    display: block;
}
.mobile {
    display: none;
}
@media (max-width: 30em) {
    .desktop {
        display: none;
    }

    .mobile {
        display: block;
    }
}
/**
 * CSS for colors
 */
/* stylelint-disable color-no-hex */
:root {
    /* TIER ONE - DESCRIPTIVE NAMES */

    /* Default / Text color + Light background / Background color + Light text */        /* dark orange */          /* brand blue */           /* red */        /* purple */         /* dark green */          /* gray */            /* white */        /* yellow */

    /* Highlight / Text color + Dark background / Background color + Dark text */                                /* orange */            /* light red #f0425f */      /* light purple #ce51e1 */                                 /* bright green */                                  /* bright blue - brand color */                                /* light yellow */

    /* Alerts */

    /* Additional blues */         /* dark blue #003366 */  /* medium blue #0066cc */ /* light blue #c7e3ff */  /* light blue #e5f2ff */

    /* Additional reds */  /* light red #fbd0d7 */  /* dark red #8E0B21 */

    /* Additional greens */                                 /* dark green */ /* light green #e5fff1 */

    /* Additional purples */    /* extra light purple #f3d3f8 */       /* very light purple #f3d3f840 */

    /* Additional oranges */    /* light orange #ffe8cc */

    /* Grayscale Colors */  /* Near black */ /* Dark gray #1d2830 */  /* Formerly graphite */       /* medium gray */ /* #b1b2b4 */        /* #d8d9da */  /* Near white #f2f2f3 */

    /* TIER TWO - SEMANTIC NAMES */

    /* Product Colors */

    /* Component Colors */

    /* Partner/Refi Lender Colors */

    /* First Republic palette */    /* forest green */        /* gold hacienda */        /* tan gray concrete */      /* dark gray matterhorn */

    /* Visualization palette */

    /* Marketing Page Colors */ /* #1d2830; */ /* #005cb9; *//* #0f1519; */ /* #1d2830; */ /* #5a666e; *//* #8a8c8f; */ /* #b1b2b4; */ /* #d7d8d9; *//* #f2f2f3; */ /* #e5f2ff; */ /* #eddef0; */
}
/**
 * CSS for text, titles, labels. word.
 */
/* Marketing Text Mixins */
/**
 * CSS for laying out and displaying blocks and boxes
 */
:root {

    /* Consistent paddings */

    /* shadows */

    /* All z-index values should be listed here */

    /* widths */
}
/* basic setup for triangles in :before and :after pseudo-elements */
/* clears ul/ol defaults */
/* TODO Deprecated */
/* best pronounced with a texan drawl */
/* is this annoying yet? */
/* Marketing Button Mixins */
.marketing-video-open-button {
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 0;
    border-width: 0;
    background-color: transparent;
    max-width: 100%;
    padding: 0;
}
.marketing-video-open-button:hover {
        background-color: transparent;
    }
.marketing-video-open-button .play-video {
        display: inline-block;
        text-decoration: none;
        padding: 8px 16px;
        border-radius: 24px;
        cursor: pointer;
        -webkit-transition: all 0.2s ease-in;
        -o-transition: all 0.2s ease-in;
        transition: all 0.2s ease-in;
        text-align: center;

        display: -webkit-box;

        display: -webkit-flex;

        display: -moz-box;

        display: -ms-flexbox;

        display: flex;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
           -moz-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        color: white;
        border: 1px solid white;
        background-color: transparent;
        -webkit-transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
        position: absolute;
        top: 50%;
        left: 50%;
    }
@media (max-width: 40em) {
.marketing-video-open-button .play-video {
        height: 50px;
        margin: 8px 0;
        padding: 16px 16px 8px 16px;
        text-align: center;
        width: 100%
    }
    }
.marketing-video-open-button .play-video .icon-play-circled {
            margin-left: 4px;
            font-size: 1.2rem;
            line-height: 1rem;
            vertical-align: middle;
        }
.marketing-video-open-button .button-preview {
        max-width: 100%;
        max-height: 100%;
    }
.marketing-video-open-button .overlay {
        background-image: url("./assets/video_thumbnail_overlay.png");
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-size: 100%;
    }
.marketing-video-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    margin: 16px 0 32px;
}
.marketing-video-container iframe.marketing-video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
.marketing-video-container .loader {
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
        margin: 8px;
    }
@media (max-width: 40em) {
    .marketing-video-open-button {
        height: 100%;
    }

        .marketing-video-open-button .play-video {
            border: 0;
            -webkit-box-align: end;
            -webkit-align-items: flex-end;
               -moz-box-align: end;
                -ms-flex-align: end;
                    align-items: flex-end;
        }

            .marketing-video-open-button .play-video .icon-play-circled {
                font-size: 5rem;
            }
}
