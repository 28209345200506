/**
 * CSS for laying out and displaying blocks and boxes
 */
:root {

    /* Consistent paddings */

    /* shadows */

    /* All z-index values should be listed here */

    /* widths */
}
/* this section is deprecated - prefer to use device-specific names if possible */
/* device based size names */
/* iPhone 5 width: 20em */
/* iPhone 6 width: */
/* iPhone 5 height: 35.5em */
/* iPhone 6 plus height: 46em */
/* iPad width: 48em */
/* iPad height: 64em */
.desktop {
    display: block;
}
.mobile {
    display: none;
}
@media (max-width: 30em) {
    .desktop {
        display: none;
    }

    .mobile {
        display: block;
    }
}
/**
 * CSS for colors
 */
/* stylelint-disable color-no-hex */
:root {
    /* TIER ONE - DESCRIPTIVE NAMES */

    /* Default / Text color + Light background / Background color + Light text */        /* dark orange */          /* brand blue */           /* red */        /* purple */         /* dark green */          /* gray */            /* white */        /* yellow */

    /* Highlight / Text color + Dark background / Background color + Dark text */                                /* orange */            /* light red #f0425f */      /* light purple #ce51e1 */                                 /* bright green */                                  /* bright blue - brand color */                                /* light yellow */

    /* Alerts */

    /* Additional blues */         /* dark blue #003366 */  /* medium blue #0066cc */ /* light blue #c7e3ff */  /* light blue #e5f2ff */

    /* Additional reds */  /* light red #fbd0d7 */  /* dark red #8E0B21 */

    /* Additional greens */                                 /* dark green */ /* light green #e5fff1 */

    /* Additional purples */    /* extra light purple #f3d3f8 */       /* very light purple #f3d3f840 */

    /* Additional oranges */    /* light orange #ffe8cc */

    /* Grayscale Colors */  /* Near black */ /* Dark gray #1d2830 */  /* Formerly graphite */       /* medium gray */ /* #b1b2b4 */        /* #d8d9da */  /* Near white #f2f2f3 */

    /* TIER TWO - SEMANTIC NAMES */

    /* Product Colors */

    /* Component Colors */

    /* Partner/Refi Lender Colors */

    /* First Republic palette */    /* forest green */        /* gold hacienda */        /* tan gray concrete */      /* dark gray matterhorn */

    /* Visualization palette */

    /* Marketing Page Colors */ /* #1d2830; */ /* #005cb9; *//* #0f1519; */ /* #1d2830; */ /* #5a666e; *//* #8a8c8f; */ /* #b1b2b4; */ /* #d7d8d9; *//* #f2f2f3; */ /* #e5f2ff; */ /* #eddef0; */
}
/**
 * CSS for text, titles, labels. word.
 */
/* Marketing Text Mixins */
@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
#view .page-header .full-screen-header {
            background-color: white;
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
        }
#view .page-header .full-screen-header .site-logo-container {
                padding-top: 0;
            }
#view .page-header .full-screen-header .site-logo {
                background: url("../../gradifui/assets/Gradifi-Logo-Horiz-Color.svg") left center no-repeat;
                background-size: 250px 64px;
            }
#view .page-header .full-screen-header .sub-items {
                display: -webkit-box;
                display: -webkit-flex;
                display: -moz-box;
                display: -ms-flexbox;
                display: flex;
                background-color: white;
                color: rgb(29, 40, 48);
            }
#view .page-header .full-screen-header .sub-items .header-item {
                    color: black;
                }
#view .page-header .full-screen-header .sub-items .header-item .label-link {
                        -webkit-box-pack: center;
                        -webkit-justify-content: center;
                           -moz-box-pack: center;
                            -ms-flex-pack: center;
                                justify-content: center;
                    }
#view .page-header .small-screen-header .sub-item-container {
                right: 0;
            }
#view .page-header .full-screen-header,
        #view .page-header .small-screen-header {
            -webkit-box-shadow: 10px 20px 80px 0 rgba(11, 42, 65, 0.17);
                    box-shadow: 10px 20px 80px 0 rgba(11, 42, 65, 0.17);
        }
#view.inverted-header .page-header .full-screen-header {
        background-color: white;
    }
#view .btn-demo-header {
        margin-left: 16px;
    }
#view .full-screen-header .marketing-header-solutions .header-item:not(.menu-open) .sub-item-container {
        pointer-events: none;
    }
img.right-arrow {
    height: 14px;
    width: 16px;
    color: #005cb9;
    -webkit-margin-after: 0;
            margin-block-end: 0;
}
.labelled-item .label-link {
        color: black;
    }
.labelled-item .label-link:hover {
            color: black;
        }
.labelled-item .label-link.solutions-link {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: reverse;
        -webkit-flex-direction: row-reverse;
           -moz-box-orient: horizontal;
           -moz-box-direction: reverse;
            -ms-flex-direction: row-reverse;
                flex-direction: row-reverse;
        cursor: default;
    }
.labelled-item .label-link.solutions-link i {
            margin-top: 2px;
            color: #005cb9;
        }
.page-header .small-screen-header .header-item .sub-item-container .right-section .marketing-header-solutions > .header-item.labelled-item {
            height: auto;
        }
.page-header .small-screen-header .header-item .sub-item-container .right-section .marketing-header-solutions .solutions-link {
            display: none;
        }
.page-header .small-screen-header .header-item .sub-item-container .right-section .marketing-header-solutions .header-item {
            padding-left: 0;
            margin-bottom: 0;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -webkit-flex-direction: column;
               -moz-box-orient: vertical;
               -moz-box-direction: normal;
                -ms-flex-direction: column;
                    flex-direction: column;
        }
.page-header .small-screen-header .header-item .sub-item-container .right-section .marketing-header-solutions .header-item .sub-item-container {
                overflow: visible;
                height: auto;
            }
.page-header .small-screen-header .header-item .sub-item-container .right-section .marketing-header-solutions .header-item .sub-item-container .sub-items .header-item.labelled-item {
                    height: 54px;
                    margin-bottom: 0;
                    padding-left: 0;
                    -webkit-box-align: start;
                    -webkit-align-items: start;
                       -moz-box-align: start;
                        -ms-flex-align: start;
                            align-items: start;
                }
.page-header .small-screen-header .header-item .sub-item-container .right-section .marketing-header-solutions .header-item .sub-item-container .sub-items .header-item.labelled-item:last-of-type {
                        height: 44px;
                    }
.page-header .small-screen-header .header-item .sub-item-container .right-section > .header-item.labelled-item {
        height: 54px;
        margin-bottom: 0;
        padding-left: 0;
    }
.marketing-header-solutions .flyout-tile-image {
        height: 70px;
        width: 70px;
    }
.marketing-header-solutions .flyout-tile {
        text-align: center;
        padding: 48px 0;
        border-bottom: 4px solid;
        cursor: pointer;
    }
.marketing-header-solutions .flyout-tile:hover {
            background: rgb(229, 242, 255);
        }
.marketing-header-solutions .product-overview .flyout-tile-image {
            height: 70px;
            width: 90px;
        }
.marketing-header-solutions .product-overview .flyout-tile-header {
            color: rgb(29, 40, 48);
        }
.marketing-header-solutions .product-overview {

        border-bottom-color: #005cb9;
}
.marketing-header-solutions .fincore .flyout-tile-header {
            color: #009d4f;
        }
.marketing-header-solutions .fincore {

        border-bottom-color: #00b451;
}
.marketing-header-solutions .slp .flyout-tile-header {
            color: #005cb9;
        }
.marketing-header-solutions .slp {

        border-bottom-color: #00a0df;
}
.marketing-header-solutions .csu .flyout-tile-header {
            color: #8a1a9b;
        }
.marketing-header-solutions .csu {

        border-bottom-color: #b14fc5;
}
.small-screen-header .header-item.mobile-menu-item:not(.menu-open) {
    -webkit-box-shadow: 10px 20px 80px 0 rgba(11, 42, 65, 0.17);
            box-shadow: 10px 20px 80px 0 rgba(11, 42, 65, 0.17);
}
.small-screen-header .menu-open .mobile-menu-container {
        background-color: transparent;
    }
.small-screen-header .menu-open .mobile-menu-container .mobile-header-container-marketing {
            background-color: transparent;
        }
.small-screen-header .menu-open .mobile-menu-container .menu-button-marketing .menu-toggle-marketing,
        .small-screen-header .menu-open .mobile-menu-container .menu-button-marketing::before,
        .small-screen-header .menu-open .mobile-menu-container .menu-button-marketing::after {
            background-color: #fff;
        }
.small-screen-header .menu-open .mobile-menu-container .site-logo-container .site-logo-marketing {
            background: url("../../gradifui/assets/Gradifi-Logo-Horiz-White.svg") left center no-repeat;
            background-size: contain;
        }
.small-screen-header .menu-open .mobile-menu-container .menu-overlay {
            background: transparent;
        }
.small-screen-header .menu-open .mobile-menu-container .btn-demo {
            display: none;
        }
.small-screen-header .menu-open .mobile-menu-container .spacer {
            display: none;
        }
.small-screen-header .menu-open.mobile-menu-item .sub-item-container {
            -webkit-animation: fadeIn 400ms ease;
                    animation: fadeIn 400ms ease;
        }
.small-screen-header .menu-open.mobile-menu-item .sub-item-container,
        .small-screen-header .menu-open.mobile-menu-item .sub-items {
            background-color: #005cb9;
        }
.small-screen-header .menu-open.mobile-menu-item .sub-item-container {
            position: absolute;
            top: 0;
            padding: 0;
        }
.small-screen-header .menu-open.mobile-menu-item .sub-items {
            display: -webkit-box;
            display: -webkit-flex;
            display: -moz-box;
            display: -ms-flexbox;
            display: flex;
            background-image: url("../assets/redesign2020/header/mobile-background-chevron.svg");
            background-size: contain;
            background-repeat: no-repeat;
            background-position: bottom right;
        }
.small-screen-header .menu-open.mobile-menu-item .marketing-header-mobile {
            display: -webkit-box;
            display: -webkit-flex;
            display: -moz-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -webkit-flex-direction: column;
               -moz-box-orient: vertical;
               -moz-box-direction: normal;
                -ms-flex-direction: column;
                    flex-direction: column;
            text-align: center;
            margin: 96px auto 0;
        }
.small-screen-header .menu-open.mobile-menu-item .marketing-header-mobile .csu,
            .small-screen-header .menu-open.mobile-menu-item .marketing-header-mobile .slp,
            .small-screen-header .menu-open.mobile-menu-item .marketing-header-mobile .fincore,
            .small-screen-header .menu-open.mobile-menu-item .marketing-header-mobile .product-overview,
            .small-screen-header .menu-open.mobile-menu-item .marketing-header-mobile .product-header,
            .small-screen-header .menu-open.mobile-menu-item .marketing-header-mobile .about,
            .small-screen-header .menu-open.mobile-menu-item .marketing-header-mobile .logout,
            .small-screen-header .menu-open.mobile-menu-item .marketing-header-mobile .login {
                font-family: ETrade-Bold;
                line-height: 100%;
                color: rgb(29, 40, 48);

                color: #fff;
                text-decoration: none;
                margin: 8px 0;
            }
.small-screen-header .menu-open.mobile-menu-item .marketing-header-mobile .csu,
            .small-screen-header .menu-open.mobile-menu-item .marketing-header-mobile .slp,
            .small-screen-header .menu-open.mobile-menu-item .marketing-header-mobile .fincore,
            .small-screen-header .menu-open.mobile-menu-item .marketing-header-mobile .request-demo,
            .small-screen-header .menu-open.mobile-menu-item .marketing-header-mobile .product-overview {
                font-family: ETrade-Semibold;
                line-height: 120%;
                color: rgb(29, 40, 48);

                font-size: 1rem;
                color: #fff;
            }
.small-screen-header .menu-open.mobile-menu-item .marketing-header-mobile .product-header,
            .small-screen-header .menu-open.mobile-menu-item .marketing-header-mobile .about,
            .small-screen-header .menu-open.mobile-menu-item .marketing-header-mobile .request-demo,
            .small-screen-header .menu-open.mobile-menu-item .marketing-header-mobile .login,
            .small-screen-header .menu-open.mobile-menu-item .marketing-header-mobile .logout {
                font-size: 1.25rem;
            }
.small-screen-header .menu-open.mobile-menu-item .marketing-header-mobile .product-header-checkbox {
                display: none;
            }
.small-screen-header .menu-open.mobile-menu-item .marketing-header-mobile .product-header::after {
                    content: "▼";
                    display: inline-block;
                    margin-left: 2px;
                    -webkit-transition: -webkit-transform 300ms ease-in;
                    transition: -webkit-transform 300ms ease-in;
                    -o-transition: transform 300ms ease-in;
                    transition: transform 300ms ease-in;
                    transition: transform 300ms ease-in, -webkit-transform 300ms ease-in;
                    -webkit-transition-delay: 0;
                         -o-transition-delay: 0;
                            transition-delay: 0;
                }
.small-screen-header .menu-open.mobile-menu-item .marketing-header-mobile .product-dropdown {
                display: -webkit-box;
                display: -webkit-flex;
                display: -moz-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                -webkit-flex-direction: column;
                   -moz-box-orient: vertical;
                   -moz-box-direction: normal;
                    -ms-flex-direction: column;
                        flex-direction: column;
                margin: 16px 0 0;
                max-height: 0;
                overflow: hidden;
                -webkit-transition: max-height 400ms ease-out;
                -o-transition: max-height 400ms ease-out;
                transition: max-height 400ms ease-out;
            }
.small-screen-header .menu-open.mobile-menu-item .marketing-header-mobile .product-header-checkbox:checked ~ .product-dropdown {
                    max-height: 1000px;
                    -webkit-transition-duration: 500ms;
                         -o-transition-duration: 500ms;
                            transition-duration: 500ms;
                    -webkit-transition-timing-function: ease-in;
                         -o-transition-timing-function: ease-in;
                            transition-timing-function: ease-in;
                }
.small-screen-header .menu-open.mobile-menu-item .marketing-header-mobile .product-header-checkbox:checked ~ .product-header::after {
                    -webkit-transform: rotate(180deg);
                        -ms-transform: rotate(180deg);
                            transform: rotate(180deg);
                }
.small-screen-header .menu-open.mobile-menu-item .marketing-header-mobile .about,
            .small-screen-header .menu-open.mobile-menu-item .marketing-header-mobile .request-demo,
            .small-screen-header .menu-open.mobile-menu-item .marketing-header-mobile .login,
            .small-screen-header .menu-open.mobile-menu-item .marketing-header-mobile .logout {
                margin-top: 24px;
            }
.small-screen-header .menu-open.mobile-menu-item .marketing-header-mobile .request-demo {
                border-radius: 32px;
                font-size: 0.75rem;
                font-weight: 600;
                text-align: center;
                color: #fff;
                background-color: #005cb9;
                -webkit-box-shadow: 0 21px 30px 0 rgba(0, 0, 0, 0.27);
                        box-shadow: 0 21px 30px 0 rgba(0, 0, 0, 0.27);
                -webkit-transition: color 0.2s ease-in, background-color 0.2s ease-in;
                -o-transition: color 0.2s ease-in, background-color 0.2s ease-in;
                transition: color 0.2s ease-in, background-color 0.2s ease-in;
            }
.small-screen-header .menu-open.mobile-menu-item .marketing-header-mobile .request-demo:hover {
        color: rgb(29, 40, 48);
        background-color: #fff;
    }
.small-screen-header .menu-open.mobile-menu-item .marketing-header-mobile .request-demo {

                background: transparent;
                letter-spacing: 1px;
                text-decoration: none;
                border: 2px solid #fff;
                padding: 16px 32px;
                -webkit-box-shadow: none;
                        box-shadow: none;
}
@media (min-width: 40em) {
                .small-screen-header .menu-open.mobile-menu-item .marketing-header-mobile .csu,
                .small-screen-header .menu-open.mobile-menu-item .marketing-header-mobile .slp,
                .small-screen-header .menu-open.mobile-menu-item .marketing-header-mobile .fincore,
                .small-screen-header .menu-open.mobile-menu-item .marketing-header-mobile .request-demo,
                .small-screen-header .menu-open.mobile-menu-item .marketing-header-mobile .product-overview {
                    font-size: 1.5rem;
                    margin: 16px 0;
                }

                .small-screen-header .menu-open.mobile-menu-item .marketing-header-mobile .product-header,
                .small-screen-header .menu-open.mobile-menu-item .marketing-header-mobile .about,
                .small-screen-header .menu-open.mobile-menu-item .marketing-header-mobile .login,
                .small-screen-header .menu-open.mobile-menu-item .marketing-header-mobile .logout {
                    font-size: 2rem;
                    margin: 48px 0 24px;
                }

                .small-screen-header .menu-open.mobile-menu-item .marketing-header-mobile .request-demo {
                    margin-top: 40px;
                }
}
@media (max-width: 65em) {
    .btn-demo-header-mobile {
        display: none;
    }
        .small-screen-header .menu-open > .sub-item-container {
            display: static;
        }

        .small-screen-header > :not(.menu-open) > .sub-item-container {
            display: none;
        }
}
@media (min-width: 60em) {
                    .full-screen-header .right-section .marketing-header-solutions .header-item .sub-item-container {
                        position: fixed;
                        left: 0;
                        right: 0;
                    }
                .full-screen-header .labelled-item .label-link.login-link .header-label, .full-screen-header .labelled-item .label-link.solutions-link .header-label {
                    display: block;
                }

        .full-screen-header .flyout-container {
            display: -webkit-box;
            display: -webkit-flex;
            display: -moz-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
            -webkit-flex-direction: row;
               -moz-box-orient: horizontal;
               -moz-box-direction: normal;
                -ms-flex-direction: row;
                    flex-direction: row;
            -webkit-box-pack: justify;
            -webkit-justify-content: space-between;
               -moz-box-pack: justify;
                -ms-flex-pack: justify;
                    justify-content: space-between;
            width: 100%;
        }
}
