/* this section is deprecated - prefer to use device-specific names if possible */
/* device based size names */
/* iPhone 5 width: 20em */
/* iPhone 6 width: */
/* iPhone 5 height: 35.5em */
/* iPhone 6 plus height: 46em */
/* iPad width: 48em */
/* iPad height: 64em */
.desktop {
    display: block;
}
.mobile {
    display: none;
}
@media (max-width: 30em) {
    .desktop {
        display: none;
    }

    .mobile {
        display: block;
    }
}
/**
 * CSS for colors
 */
/* stylelint-disable color-no-hex */
:root {
    /* TIER ONE - DESCRIPTIVE NAMES */

    /* Default / Text color + Light background / Background color + Light text */        /* dark orange */          /* brand blue */           /* red */        /* purple */         /* dark green */          /* gray */            /* white */        /* yellow */

    /* Highlight / Text color + Dark background / Background color + Dark text */                                /* orange */            /* light red #f0425f */      /* light purple #ce51e1 */                                 /* bright green */                                  /* bright blue - brand color */                                /* light yellow */

    /* Alerts */

    /* Additional blues */         /* dark blue #003366 */  /* medium blue #0066cc */ /* light blue #c7e3ff */  /* light blue #e5f2ff */

    /* Additional reds */  /* light red #fbd0d7 */  /* dark red #8E0B21 */

    /* Additional greens */                                 /* dark green */ /* light green #e5fff1 */

    /* Additional purples */    /* extra light purple #f3d3f8 */       /* very light purple #f3d3f840 */

    /* Additional oranges */    /* light orange #ffe8cc */

    /* Grayscale Colors */  /* Near black */ /* Dark gray #1d2830 */  /* Formerly graphite */       /* medium gray */ /* #b1b2b4 */        /* #d8d9da */  /* Near white #f2f2f3 */

    /* TIER TWO - SEMANTIC NAMES */

    /* Product Colors */

    /* Component Colors */

    /* Partner/Refi Lender Colors */

    /* First Republic palette */    /* forest green */        /* gold hacienda */        /* tan gray concrete */      /* dark gray matterhorn */

    /* Visualization palette */

    /* Marketing Page Colors */ /* #1d2830; */ /* #005cb9; *//* #0f1519; */ /* #1d2830; */ /* #5a666e; *//* #8a8c8f; */ /* #b1b2b4; */ /* #d7d8d9; *//* #f2f2f3; */ /* #e5f2ff; */ /* #eddef0; */
}
/**
 * CSS for text, titles, labels. word.
 */
/* Marketing Text Mixins */
/**
 * CSS for laying out and displaying blocks and boxes
 */
:root {

    /* Consistent paddings */

    /* shadows */

    /* All z-index values should be listed here */

    /* widths */
}
.site-content {
    height: 100%;
}
body {
    background: #fff;
}
.highlighted {
    color: #005cb9;
}
.legal-text-1::after,
.legal-text-2::after,
.legal-text-3::after,
.legal-text-4::after,
.legal-text-5::after {
    font-size: 0.5rem;
    vertical-align: super;
}
.legal-text-1::after {
    content: "1";
}
.legal-text-2::after {
    content: "2";
}
.legal-text-3::after {
    content: "3";
}
.legal-text-4::after {
    content: "4";
}
.legal-text-5::after {
    content: "5";
}
.marketing-home-background-image {
    position: absolute;
    right: 0;
    width: 39vw;
    top: 300px;
    max-width: 800px;
}
.marketing-home {
    max-width: 1328px;
    margin: 64px auto 0;
    text-rendering: optimizeLegibility;
}
.marketing-home .highlighted {
        color: #005cb9;
    }
.marketing-home .button {
        text-transform: uppercase;
    }
.marketing-home .marketing-hero {
        padding: 0 16px;
    }
.marketing-home .marketing-hero .highlighted {
            display: inline;
        }
.marketing-home .talent-card,
    .marketing-home .impact-card-wrapper,
    .marketing-home .logo-garden-wrapper,
    .marketing-home .solutions {
        max-width: 1180px;
    }
.marketing-home .talent-card,
    .marketing-home .impact-card-wrapper,
    .marketing-home .logo-garden-wrapper {
        width: 100%;
        padding: 0 16px;
        margin: 32px auto 0;
    }
.marketing-home .logo-garden-wrapper {
        margin-top: 56px;
    }
.marketing-home .solutions {
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        padding: 0 16px;
        margin: 72px auto 0;
    }
.marketing-home .solutions .solutions-image {
            margin: auto;
            width: 100%;
            height: 100%;
            max-width: 500px;
            -webkit-flex-shrink: 0;
                -ms-flex-negative: 0;
                    flex-shrink: 0;
        }
.marketing-home .solutions .solutions-header {
            font-family: ETrade-Bold;
            line-height: 100%;
            color: rgb(29, 40, 48);
            font-size: 2rem;
            line-height: 120%;
        }
.marketing-home .solutions .solutions-subheader,
        .marketing-home .solutions .solutions-body-text {
            padding-left: 64px;
            padding-right: 40px;
        }
.marketing-home .solutions .solutions-body-text {
            font-family: ETrade-Regular;
            line-height: 150%;
            color: rgb(29, 40, 48);
            font-size: 1rem;

            margin: 24px 0;
        }
.marketing-home .solutions .solutions-subheader {
            font-family: ETrade-Semibold;
            line-height: 120%;
            color: rgb(29, 40, 48);
            font-size: 1.5rem;
            line-height: 150%;
        }
.marketing-home .solutions .solutions-etrade-icon {
            margin-left: 2px;
            top: 2px;
            height: 1.5rem;
        }
.marketing-home .solutions .solutions-etrade-logo {
            white-space: pre;
        }
.marketing-home .solutions .solutions-body-link {
            font-family: ETrade-Regular;
            line-height: 150%;
            color: rgb(29, 40, 48);
            font-size: 0.75rem;
            font-weight: 600;
            padding-bottom: 2px;
            border-bottom: 2px solid #005cb9;

            font-family: ETrade-Semibold;
            text-transform: uppercase;
            letter-spacing: 1px;
            margin-left: 64px;
            text-decoration: none;
            margin-bottom: auto;
            max-width: 70px;
        }
.marketing-home .solutions-etrade-icon-large {
        top: 2px;
        height: 28px;
    }
.marketing-home .impact-card-wrapper {
        margin-top: 56px;
    }
.marketing-home .impact-card {
        display: -ms-grid;
        display: grid;
        padding: 56px;
        border-radius: 8px;
        -webkit-box-shadow: 10px 20px 80px 0 rgba(11, 42, 65, 0.17);
                box-shadow: 10px 20px 80px 0 rgba(11, 42, 65, 0.17);
        background: transparent;
            grid-template-areas:
            "impact-image impact-body"
            "impact-button impact-body";
    }
.marketing-home .impact-card::before {
            content: "";
            top: 0;
            left: 0;
            position: absolute;
            border-radius: 8px;
            height: 100%;
            width: 100%;
            background: #fff;
            -webkit-backdrop-filter: blur(8px);
                    backdrop-filter: blur(8px);
        }
@supports ((-webkit-backdrop-filter: blur(8px)) or (backdrop-filter: blur(8px))) {
            .marketing-home .impact-card::before {
                background: -webkit-gradient(linear, left top, left bottom, from(rgba(248, 251, 253, 0.56)), to(rgba(239, 245, 251, 0.46)));
                background: -o-linear-gradient(top, rgba(248, 251, 253, 0.56) 0%, rgba(239, 245, 251, 0.46) 100%);
                background: linear-gradient(180deg, rgba(248, 251, 253, 0.56) 0%, rgba(239, 245, 251, 0.46) 100%);
            }
        }
.marketing-home .impact-card .impact-image {
            -ms-grid-row: 1;
            -ms-grid-column: 1;
            grid-area: impact-image;
            width: 100%;
            margin-top: auto;
            padding-right: 32px;
            max-width: 486px;
        }
.marketing-home .impact-card *::-ms-backdrop,
        .marketing-home .impact-card .impact-image {
            height: 35vw;
            max-height: 337px;
        }
.marketing-home .impact-card .impact-body {
            -ms-grid-row: 1;
            -ms-grid-row-span: 2;
            -ms-grid-column: 2;
            grid-area: impact-body;
            width: 100%;
            max-width: 517px;
        }
.marketing-home .impact-card .button {
            color: #fff;
            background-color: #005cb9;
            -webkit-box-shadow: 0 21px 30px 0 rgba(0, 0, 0, 0.27);
                    box-shadow: 0 21px 30px 0 rgba(0, 0, 0, 0.27);
            -webkit-transition: color 0.2s ease-in, background-color 0.2s ease-in;
            -o-transition: color 0.2s ease-in, background-color 0.2s ease-in;
            transition: color 0.2s ease-in, background-color 0.2s ease-in;
        }
.marketing-home .impact-card .button:hover {
        color: rgb(29, 40, 48);
        background-color: #fff;
    }
.marketing-home .impact-card .button {
            -ms-grid-row: 2;
            -ms-grid-column: 1;
            border-radius: 32px;
            font-size: 0.75rem;
            font-weight: 600;
            text-align: center;

            display: -webkit-box;

            display: -webkit-flex;

            display: -moz-box;

            display: -ms-flexbox;

            display: flex;
            -webkit-box-align: center;
            -webkit-align-items: center;
               -moz-box-align: center;
                -ms-flex-align: center;
                    align-items: center;
            -webkit-box-pack: center;
            -webkit-justify-content: center;
               -moz-box-pack: center;
                -ms-flex-pack: center;
                    justify-content: center;
            padding: 0;
            height: 48px;
            letter-spacing: 1.1px;
            width: 180px;
            grid-area: impact-button;
            margin: 16px auto 24px;
}
.marketing-home .impact-card .chevron {
            width: 56px;
        }
.marketing-home .impact-card .impact-header {
            font-family: ETrade-Bold;
            line-height: 100%;
            color: rgb(29, 40, 48);
            font-size: 2rem;
            line-height: 120%;
        }
.marketing-home .impact-card .impact-body-text {
            font-family: ETrade-Regular;
            line-height: 150%;
            color: rgb(29, 40, 48);
            font-size: 1rem;

            padding-left: 104px;
            margin: 16px 0;
        }
.marketing-home .impact-card .impact-subheader {
            font-family: ETrade-Semibold;
            line-height: 120%;
            color: rgb(29, 40, 48);
            font-size: 1.5rem;
            line-height: 150%;
        }
.marketing-home *::-ms-backdrop,
    .marketing-home .impact-card {
        background: #fff;
    }
.marketing-home .logo-garden {
        margin: 32px 0 0;
    }
.marketing-home .talent-card-background-desktop,
    .marketing-home .talent-card-background-tablet {
        position: absolute;
        max-height: 150%;
        width: 65%;
        bottom: 5px;
        z-index: -1;
    }
.marketing-home .talent-card-background-desktop {
        width: 61%;
    }
.marketing-home *::-ms-backdrop,
    .marketing-home .talent-card-background-desktop,
    .marketing-home .talent-card-background-tablet {
        background-image: url("../assets/redesign2020/home/background-triangle-desktop.png");
        width: 50%;
        background-repeat: no-repeat;
        background-size: contain;
    }
.marketing-home .talent-card {
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -webkit-flex-direction: row;
           -moz-box-orient: horizontal;
           -moz-box-direction: normal;
            -ms-flex-direction: row;
                flex-direction: row;
        margin-top: 48px;
        padding: 0;
    }
.marketing-home .talent-card .chevron {
            width: 56px;
            margin-top: auto;
            margin-bottom: 32px;
        }
.marketing-home .talent-card .talent-card-image {
            margin-left: auto;
            width: 100%;
            max-width: 550px;
        }
.marketing-home .talent-card .talent-card-background-tablet {
            display: none;
        }
.marketing-home .talent-card .talent-card-header {
            font-family: ETrade-Bold;
            line-height: 100%;
            color: rgb(29, 40, 48);
            font-size: 2.5rem;
            line-height: 120%;

            font-size: 2.4rem;
            margin-bottom: 24px;
        }
.marketing-home .talent-card .talent-card-header .sentence {
                display: block;
            }
.marketing-home .talent-card .talent-card-header .sentence:first-of-type {
                    white-space: pre;
                }
.marketing-home .talent-card .talent-card-body {
            display: -webkit-box;
            display: -webkit-flex;
            display: -moz-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -webkit-flex-direction: column;
               -moz-box-orient: vertical;
               -moz-box-direction: normal;
                -ms-flex-direction: column;
                    flex-direction: column;
            margin: 16px auto auto;
            padding-left: 40px;
            max-width: 480px;
        }
.marketing-home .talent-card .talent-card-text {
            font-family: ETrade-Regular;
            line-height: 150%;
            color: rgb(29, 40, 48);
            font-size: 1rem;

            max-width: 380px;
            margin-bottom: 24px;
        }
.marketing-home .talent-card .talent-card-link {
            font-family: ETrade-Regular;
            line-height: 150%;
            color: rgb(29, 40, 48);
            font-size: 0.75rem;
            font-weight: 600;
            padding-bottom: 2px;
            border-bottom: 2px solid #005cb9;

            font-family: ETrade-Semibold;
            text-transform: uppercase;
            text-decoration: none;
            letter-spacing: 1px;
            margin-bottom: auto;
            max-width: 90px;
            margin-bottom: 40px;
        }
@media (max-width: 50em) {
    .marketing-home-background-image {
        top: 350px;
        width: 39vw;
        max-width: 480px;
    }

    .marketing-home {
        max-width: 100%;
    }
            .marketing-home .solutions .solutions-image {
                max-width: 50%;
            }

            .marketing-home .solutions .solutions-subheader,
            .marketing-home .solutions .solutions-body-text {
                padding-right: 0;
            }
            .marketing-home .impact-card .impact-image {
                margin: auto;
            }

            .marketing-home .impact-card .impact-header {
                font-size: 1.5rem;
                max-width: 420px;
            }

            .marketing-home .impact-card .impact-subheader {
                font-size: 1.25rem;
                max-width: 420px;
            }

            .marketing-home .impact-card .impact-button {
                margin-top: 0;
            }

            .marketing-home .impact-card .impact-body-text {
                padding-left: 0;
            }

        .marketing-home .talent-card-background-desktop {
            display: none;
        }

        .marketing-home .talent-card {
            max-width: 100%;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -webkit-flex-direction: column;
               -moz-box-orient: vertical;
               -moz-box-direction: normal;
                -ms-flex-direction: column;
                    flex-direction: column;
            margin-bottom: 0;
        }

            .marketing-home .talent-card .talent-card-background-tablet {
                display: block;
                bottom: 5px;
                width: 90%;
            }

            .marketing-home .talent-card *::-ms-backdrop,
            .marketing-home .talent-card .talent-card-background-tablet {
                width: 100%;
                height: 100%;
            }

            .marketing-home .talent-card .talent-card-image-wrapper {
                height: 100%;
                margin-bottom: 48px;
            }


                .marketing-home .talent-card .talent-card-image-wrapper .talent-card-image {
                    max-width: 100%;
                    padding: 0 56px;
                }

            .marketing-home .talent-card .talent-card-body {
                max-width: 400px;
                padding: 0;
                margin-left: 24%;
            }

            .marketing-home .talent-card .talent-card-text {
                margin-bottom: 24px;
            }

            .marketing-home .talent-card .chevron {
                width: 40px;
                margin-bottom: 8px;
            }
}
@media (max-width: 40em) {
    .marketing-home-background-image {
        top: 540px;
        width: 60vw;
    }
        .marketing-home .solutions {
            -webkit-box-orient: vertical;
            -webkit-box-direction: reverse;
            -webkit-flex-direction: column-reverse;
               -moz-box-orient: vertical;
               -moz-box-direction: reverse;
                -ms-flex-direction: column-reverse;
                    flex-direction: column-reverse;
            margin-top: 32px;
        }

            .marketing-home .solutions .solutions-image {
                max-width: none;
                height: 100%;
                width: 100%;
            }

            .marketing-home .solutions .solutions-subheader,
            .marketing-home .solutions .solutions-body-text {
                padding-left: 0;
            }

            .marketing-home .solutions .solutions-body-link {
                margin: 0;
                margin-left: calc(50% - 60px);
            }

            .marketing-home .solutions .solutions-body-text:first-of-type {
                padding-right: 12vw;
            }

        .marketing-home .impact-card-wrapper {
            padding: 0;
        }

        .marketing-home .impact-card {
                grid-template-areas:
                "impact-image"
                "impact-body"
                "impact-body"
                "impact-button";
            padding: 16px;
            margin: 56px 0 0;
            border-radius: 0;
        }

            .marketing-home .impact-card .impact-image,
            .marketing-home .impact-card .impact-body {
                max-width: 100%;
            }

            .marketing-home .impact-card .impact-image {
                padding: 0 5%;
            }

            .marketing-home .impact-card *::-ms-backdrop,
            .marketing-home .impact-card .impact-image {
                height: 100%;
                max-height: 100%;
            }

            .marketing-home .impact-card .button {
                margin: 8px auto 16px;
            }

        .marketing-home .talent-card {
            margin-top: 24px;
        }

            .marketing-home .talent-card .talent-card-body {
                width: 100%;
                max-width: 100%;
                padding: 0 16px;
                margin: 0;
            }

            .marketing-home .talent-card .talent-card-background-tablet {
                width: 100%;
            }

            .marketing-home .talent-card .talent-card-image-wrapper {
                margin-bottom: 32px;
            }

                .marketing-home .talent-card .talent-card-image-wrapper .talent-card-image {
                    padding: 0 16px;
                }

            .marketing-home .talent-card .talent-card-link {
                margin: 0;
                margin-left: calc(50% - 44px);
            }

            .marketing-home .talent-card .talent-card-text {
                max-width: 100%;
            }

            .marketing-home .talent-card .talent-card-header {
                font-size: 1.5rem;
                max-width: 420px;
            }

                .marketing-home .talent-card .talent-card-header .sentence,
                .marketing-home .talent-card .talent-card-header .highlighted {
                    display: inline;
                    white-space: normal;
                }

                .marketing-home .talent-card .talent-card-header .sentence:first-of-type {
                    display: block;
                }

        .marketing-home .logo-garden {
            margin-top: 16px;
        }

        .marketing-home .modal-video-launcher-card {
            margin-top: 40px;
        }

        .marketing-home .impact-card .impact-image {
        -ms-grid-row: 1;
        -ms-grid-column: 1;
        }

        .marketing-home .impact-card .impact-body {
        -ms-grid-row: 2;
        -ms-grid-row-span: 2;
        -ms-grid-column: 1;
        }

        .marketing-home .impact-card .button {
        -ms-grid-row: 4;
        -ms-grid-column: 1;
        }
}
