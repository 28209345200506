/* this section is deprecated - prefer to use device-specific names if possible */
/* device based size names */
/* iPhone 5 width: 20em */
/* iPhone 6 width: */
/* iPhone 5 height: 35.5em */
/* iPhone 6 plus height: 46em */
/* iPad width: 48em */
/* iPad height: 64em */
.desktop {
    display: block;
}
.mobile {
    display: none;
}
@media (max-width: 30em) {
    .desktop {
        display: none;
    }

    .mobile {
        display: block;
    }
}
/**
 * CSS for colors
 */
/* stylelint-disable color-no-hex */
:root {
    /* TIER ONE - DESCRIPTIVE NAMES */

    /* Default / Text color + Light background / Background color + Light text */        /* dark orange */          /* brand blue */           /* red */        /* purple */         /* dark green */          /* gray */            /* white */        /* yellow */

    /* Highlight / Text color + Dark background / Background color + Dark text */                                /* orange */            /* light red #f0425f */      /* light purple #ce51e1 */                                 /* bright green */                                  /* bright blue - brand color */                                /* light yellow */

    /* Alerts */

    /* Additional blues */         /* dark blue #003366 */  /* medium blue #0066cc */ /* light blue #c7e3ff */  /* light blue #e5f2ff */

    /* Additional reds */  /* light red #fbd0d7 */  /* dark red #8E0B21 */

    /* Additional greens */                                 /* dark green */ /* light green #e5fff1 */

    /* Additional purples */    /* extra light purple #f3d3f8 */       /* very light purple #f3d3f840 */

    /* Additional oranges */    /* light orange #ffe8cc */

    /* Grayscale Colors */  /* Near black */ /* Dark gray #1d2830 */  /* Formerly graphite */       /* medium gray */ /* #b1b2b4 */        /* #d8d9da */  /* Near white #f2f2f3 */

    /* TIER TWO - SEMANTIC NAMES */

    /* Product Colors */

    /* Component Colors */

    /* Partner/Refi Lender Colors */

    /* First Republic palette */    /* forest green */        /* gold hacienda */        /* tan gray concrete */      /* dark gray matterhorn */

    /* Visualization palette */

    /* Marketing Page Colors */ /* #1d2830; */ /* #005cb9; *//* #0f1519; */ /* #1d2830; */ /* #5a666e; *//* #8a8c8f; */ /* #b1b2b4; */ /* #d7d8d9; *//* #f2f2f3; */ /* #e5f2ff; */ /* #eddef0; */
}
/**
 * CSS for text, titles, labels. word.
 */
/* Marketing Text Mixins */
/**
 * CSS for laying out and displaying blocks and boxes
 */
:root {

    /* Consistent paddings */

    /* shadows */

    /* All z-index values should be listed here */

    /* widths */
}
/* basic setup for triangles in :before and :after pseudo-elements */
/* clears ul/ol defaults */
/* TODO Deprecated */
/* best pronounced with a texan drawl */
/* is this annoying yet? */
/* Marketing Button Mixins */
.logo-item {
    min-width: 240px;
}
.logo-item .site-logo-container {
        z-index: 70;
        width: 352px;
        height: 56px;
        padding-top: 4px;
        margin-right: 24px;
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -webkit-flex-flow: row wrap;
           -moz-box-orient: horizontal;
           -moz-box-direction: normal;
            -ms-flex-flow: row wrap;
                flex-flow: row wrap;
        -webkit-box-align: center;
        -webkit-align-items: center;
           -moz-box-align: center;
            -ms-flex-align: center;
                align-items: center;
    }
.logo-item .site-logo-container .site-logo {
            background-size: cover;
            background-position: top;
            background-repeat: no-repeat;
            background-image: url("../../assets/Gradifi-Logo-Horiz-White.svg");

            width: 256px;
            height: 56px;
            background-origin: content-box;
            background-size: contain;
            display: inline-block;
        }
