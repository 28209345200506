/* this section is deprecated - prefer to use device-specific names if possible */
/* device based size names */
/* iPhone 5 width: 20em */
/* iPhone 6 width: */
/* iPhone 5 height: 35.5em */
/* iPhone 6 plus height: 46em */
/* iPad width: 48em */
/* iPad height: 64em */
.desktop {
    display: block;
}
.mobile {
    display: none;
}
@media (max-width: 30em) {
    .desktop {
        display: none;
    }

    .mobile {
        display: block;
    }
}
/**
 * CSS for colors
 */
/* stylelint-disable color-no-hex */
:root {
    /* TIER ONE - DESCRIPTIVE NAMES */

    /* Default / Text color + Light background / Background color + Light text */        /* dark orange */          /* brand blue */           /* red */        /* purple */         /* dark green */          /* gray */            /* white */        /* yellow */

    /* Highlight / Text color + Dark background / Background color + Dark text */                                /* orange */            /* light red #f0425f */      /* light purple #ce51e1 */                                 /* bright green */                                  /* bright blue - brand color */                                /* light yellow */

    /* Alerts */

    /* Additional blues */         /* dark blue #003366 */  /* medium blue #0066cc */ /* light blue #c7e3ff */  /* light blue #e5f2ff */

    /* Additional reds */  /* light red #fbd0d7 */  /* dark red #8E0B21 */

    /* Additional greens */                                 /* dark green */ /* light green #e5fff1 */

    /* Additional purples */    /* extra light purple #f3d3f8 */       /* very light purple #f3d3f840 */

    /* Additional oranges */    /* light orange #ffe8cc */

    /* Grayscale Colors */  /* Near black */ /* Dark gray #1d2830 */  /* Formerly graphite */       /* medium gray */ /* #b1b2b4 */        /* #d8d9da */  /* Near white #f2f2f3 */

    /* TIER TWO - SEMANTIC NAMES */

    /* Product Colors */

    /* Component Colors */

    /* Partner/Refi Lender Colors */

    /* First Republic palette */    /* forest green */        /* gold hacienda */        /* tan gray concrete */      /* dark gray matterhorn */

    /* Visualization palette */

    /* Marketing Page Colors */ /* #1d2830; */ /* #005cb9; *//* #0f1519; */ /* #1d2830; */ /* #5a666e; *//* #8a8c8f; */ /* #b1b2b4; */ /* #d7d8d9; *//* #f2f2f3; */ /* #e5f2ff; */ /* #eddef0; */
}
/**
 * CSS for text, titles, labels. word.
 */
/* Marketing Text Mixins */
/**
 * CSS for laying out and displaying blocks and boxes
 */
:root {

    /* Consistent paddings */

    /* shadows */

    /* All z-index values should be listed here */

    /* widths */
}
/* basic setup for triangles in :before and :after pseudo-elements */
/* clears ul/ol defaults */
/* TODO Deprecated */
/* best pronounced with a texan drawl */
/* is this annoying yet? */
/* Marketing Button Mixins */
.marketing-hero .hero-text {
        font-family: ETrade-Bold;
        line-height: 100%;
        color: rgb(29, 40, 48);

        font-size: 4rem;
    }
.marketing-hero .hero-text span {
            display: block;
            white-space: pre;
        }
.marketing-hero .mobile-image,
    .marketing-hero .tablet-image {
        display: none;
        visibility: hidden;
    }
.marketing-hero .desktop-image {
        display: block;
        visibility: visible;
        width: 100%;
        height: 100%;
        max-height: 720px;
        border-radius: 8px;
    }
.marketing-hero .hero-body {
        margin: 0 3%;
    }
.marketing-hero .hero-body-wrapper {
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 16px;
        -webkit-box-align: center;
        -webkit-align-items: center;
           -moz-box-align: center;
            -ms-flex-align: center;
                align-items: center;
    }
.marketing-hero .chevron {
        width: 82px;
        height: 41px;
        margin-bottom: 20px;
    }
.marketing-hero .button {
        color: #fff;
        background-color: #005cb9;
        -webkit-box-shadow: 0 21px 30px 0 rgba(0, 0, 0, 0.27);
                box-shadow: 0 21px 30px 0 rgba(0, 0, 0, 0.27);
        -webkit-transition: color 0.2s ease-in, background-color 0.2s ease-in;
        -o-transition: color 0.2s ease-in, background-color 0.2s ease-in;
        transition: color 0.2s ease-in, background-color 0.2s ease-in;
    }
.marketing-hero .button:hover {
        color: rgb(29, 40, 48);
        background-color: #fff;
    }
.marketing-hero .button {
        border-radius: 32px;
        font-size: 0.75rem;
        font-weight: 600;
        text-align: center;

        display: -webkit-box;

        display: -webkit-flex;

        display: -moz-box;

        display: -ms-flexbox;

        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
           -moz-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
           -moz-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        padding: 0;
        margin-top: 56px;
        width: 188px;
        height: 56px;
        letter-spacing: 1.1px;
}
@media (max-width: 71.875em) {
        .marketing-hero .hero-text {
            margin: 0;
            font-size: 5vw;
        }
}
@media (max-width: 50em) {
        .marketing-hero .desktop-image,
        .marketing-hero .mobile-image {
            display: none;
            visibility: hidden;
        }

        .marketing-hero .tablet-image {
            display: block;
            visibility: visible;
            width: 100%;
            height: 100%;
        }

        .marketing-hero .hero-body-wrapper {
            display: -webkit-box;
            display: -webkit-flex;
            display: -moz-box;
            display: -ms-flexbox;
            display: flex;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            padding: 16px;
            -webkit-box-align: center;
            -webkit-align-items: center;
               -moz-box-align: center;
                -ms-flex-align: center;
                    align-items: center;
        }

        .marketing-hero .hero-body {
            margin: 0 3%;
        }

        .marketing-hero .button {
            width: 168px;
            height: 42px;
            margin-top: 24px;
        }

        .marketing-hero .chevron {
            width: 48px;
            height: 32px;
            margin-bottom: 0;
        }

        .marketing-hero .hero-text {
            margin: 0;
            font-size: 5vw;
        }

        .marketing-hero .marketing-hero {
            padding: 0;
        }
}
@media (max-width: 40em) {
    .marketing-hero {
        margin: 0 auto;
        width: 100%;
    }

        .marketing-hero .desktop-image,
        .marketing-hero .tablet-image {
            display: none;
            visibility: hidden;
        }

        .marketing-hero .mobile-image {
            display: block;
            visibility: visible;
            -webkit-box-align: center;
            -webkit-align-items: center;
               -moz-box-align: center;
                -ms-flex-align: center;
                    align-items: center;
            width: 90vw;
            width: 100%;
        }

        .marketing-hero .chevron {
            width: 32px;
            height: 16px;
            margin-top: 8px;
            margin-bottom: 0;
        }

        .marketing-hero .hero-body-wrapper {
            position: static;
            padding: 0;
        }

        .marketing-hero .hero-body {
            margin: 0;
        }

        .marketing-hero .button {
            margin-top: 32px;
        }

        .marketing-hero .hero-text {
            font-size: 8.5vw;
        }
}
