/* this section is deprecated - prefer to use device-specific names if possible */
/* device based size names */
/* iPhone 5 width: 20em */
/* iPhone 6 width: */
/* iPhone 5 height: 35.5em */
/* iPhone 6 plus height: 46em */
/* iPad width: 48em */
/* iPad height: 64em */
.desktop {
    display: block;
}
.mobile {
    display: none;
}
@media (max-width: 30em) {
    .desktop {
        display: none;
    }

    .mobile {
        display: block;
    }
}
/**
 * CSS for colors
 */
/* stylelint-disable color-no-hex */
:root {
    /* TIER ONE - DESCRIPTIVE NAMES */

    /* Default / Text color + Light background / Background color + Light text */        /* dark orange */          /* brand blue */           /* red */        /* purple */         /* dark green */          /* gray */            /* white */        /* yellow */

    /* Highlight / Text color + Dark background / Background color + Dark text */                                /* orange */            /* light red #f0425f */      /* light purple #ce51e1 */                                 /* bright green */                                  /* bright blue - brand color */                                /* light yellow */

    /* Alerts */

    /* Additional blues */         /* dark blue #003366 */  /* medium blue #0066cc */ /* light blue #c7e3ff */  /* light blue #e5f2ff */

    /* Additional reds */  /* light red #fbd0d7 */  /* dark red #8E0B21 */

    /* Additional greens */                                 /* dark green */ /* light green #e5fff1 */

    /* Additional purples */    /* extra light purple #f3d3f8 */       /* very light purple #f3d3f840 */

    /* Additional oranges */    /* light orange #ffe8cc */

    /* Grayscale Colors */  /* Near black */ /* Dark gray #1d2830 */  /* Formerly graphite */       /* medium gray */ /* #b1b2b4 */        /* #d8d9da */  /* Near white #f2f2f3 */

    /* TIER TWO - SEMANTIC NAMES */

    /* Product Colors */

    /* Component Colors */

    /* Partner/Refi Lender Colors */

    /* First Republic palette */    /* forest green */        /* gold hacienda */        /* tan gray concrete */      /* dark gray matterhorn */

    /* Visualization palette */

    /* Marketing Page Colors */ /* #1d2830; */ /* #005cb9; *//* #0f1519; */ /* #1d2830; */ /* #5a666e; *//* #8a8c8f; */ /* #b1b2b4; */ /* #d7d8d9; *//* #f2f2f3; */ /* #e5f2ff; */ /* #eddef0; */
}
/**
 * CSS for text, titles, labels. word.
 */
/* Marketing Text Mixins */
/**
 * CSS for laying out and displaying blocks and boxes
 */
:root {

    /* Consistent paddings */

    /* shadows */

    /* All z-index values should be listed here */

    /* widths */
}
.marketing-carousel {
    max-width: 1180px;
    margin: 0 auto;
    padding: 0 16px;
}
.marketing-carousel .carousel-indicator-section {
        position: absolute;
        left: 40px;
        bottom: 40px;
        z-index: 20;
    }
.marketing-carousel .carousel-indicator-arrows {
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        position: absolute;
        top: 0%;
        width: 100%;
        height: 100%;
        -webkit-box-pack: justify;
        -webkit-justify-content: space-between;
           -moz-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between;
        -webkit-box-align: center;
        -webkit-align-items: center;
           -moz-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        z-index: 20;
        pointer-events: none;
    }
.marketing-carousel .carousel-indicator-arrows .next-child,
        .marketing-carousel .carousel-indicator-arrows .prev-child {
            height: 100%;
            padding: 0;
            font-size: 5.5rem;
            pointer-events: all;
        }
.marketing-carousel .carousel-indicator-arrows .next-child {
            padding-right: 8px;
        }
.marketing-carousel .carousel-indicator-arrows .prev-child {
            padding-left: 8px;
        }
.marketing-carousel .marketing-carousel-image-wrapper::before {
        content: "";
        position: absolute;
        background: #484071;
        height: 100%;
        width: 100%;
        border-radius: 8px;
    }
.marketing-carousel .marketing-carousel-header {
        font-family: ETrade-Bold;
        line-height: 150%;
        color: rgb(29, 40, 48);
        font-size: 1.25rem;

        text-align: center;
        margin-bottom: 24px;
    }
.marketing-carousel .marketing-carousel-image {
        display: block;
        width: 100%;
        border-radius: 8px;
        outline: none;
        -webkit-box-shadow: 0 2px 100px 0 rgba(0, 0, 0, 0.5);
                box-shadow: 0 2px 100px 0 rgba(0, 0, 0, 0.5);
    }
.marketing-carousel .marketing-carousel-logo {
        width: 32px;
        height: 32px;
        margin: 2px 8px 2px 0;
    }
.marketing-carousel .marketing-carousel-body-wrapper {
        position: absolute;
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        top: 0;
        height: 100%;
    }
.marketing-carousel .marketing-carousel-body {
        margin: 18% 0 0;
        padding-left: 40px;
        padding-bottom: 32px;
        z-index: 2;
    }
.marketing-carousel .marketing-carousel-body .button {
            color: rgb(29, 40, 48);
            background-color: #fff;
            border: 2px solid #005cb9;
            -webkit-box-shadow: 0 21px 30px 0 rgba(0, 0, 0, 0.27);
                    box-shadow: 0 21px 30px 0 rgba(0, 0, 0, 0.27);
            -webkit-transition:
        color 0.2s ease-in,
        background-color 0.2s ease-in,
        border-color 0.2s ease-in;
            -o-transition:
        color 0.2s ease-in,
        background-color 0.2s ease-in,
        border-color 0.2s ease-in;
            transition:
        color 0.2s ease-in,
        background-color 0.2s ease-in,
        border-color 0.2s ease-in;
        }
.marketing-carousel .marketing-carousel-body .button:hover {
        color: #fff;
        background-color: #005cb9;
        border-color: #fff;
    }
.marketing-carousel .marketing-carousel-body .button {
            border-radius: 32px;
            font-size: 0.75rem;
            font-weight: 600;
            text-align: center;
            display: -webkit-inline-box;
            display: -webkit-inline-flex;
            display: -moz-inline-box;
            display: -ms-inline-flexbox;
            display: inline-flex;
            -webkit-box-pack: center;
            -webkit-justify-content: center;
               -moz-box-pack: center;
                -ms-flex-pack: center;
                    justify-content: center;
            -webkit-box-align: center;
            -webkit-align-items: center;
               -moz-box-align: center;
                -ms-flex-align: center;
                    align-items: center;

            height: 48px;
            width: 160px;
            margin-top: 24px;
            border-color: #fff;
            color: #005cb9;
            font-size: 0.75rem;
            font-family: ETrade-Semibold;
            letter-spacing: 1px;
            cursor: pointer;
}
.marketing-carousel .marketing-carousel-bodytext {
        font-family: ETrade-Semibold;
        line-height: 120%;
        color: rgb(29, 40, 48);
        font-size: 2.5rem;

        margin-top: 24px;
        width: 70%;
    }
.marketing-carousel .marketing-carousel-bodytext .header-line-1 {
            display: -webkit-box;
            display: -webkit-flex;
            display: -moz-box;
            display: -ms-flexbox;
            display: flex;
            width: 80%;
        }
.marketing-carousel .marketing-carousel-bodytext .marketing-carousel-highlight {
            display: -webkit-box;
            display: -webkit-flex;
            display: -moz-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: center;
            -webkit-justify-content: center;
               -moz-box-pack: center;
                -ms-flex-pack: center;
                    justify-content: center;
            display: block;
            margin: 16px 0;
            width: 65%;
            font-size: 4.5rem;
        }
.marketing-carousel .marketing-carousel-bodytext .marketing-carousel-highlight::after {
                content: "";
                display: block;
                position: absolute;
                background: rgba(255, 255, 255, 0.15);
                bottom: 0;
                width: 67%;
                height: 50%;
            }
.marketing-carousel .marketing-carousel-bodytext .dollar-sign {
            bottom: 12px;
            margin-right: 4px;
            margin-left: 8px;
            font-size: 2rem;
        }
.marketing-carousel .marketing-carousel-overlay {
        background: url("../assets/redesign2020/marketing-carousel/fallback-overlay.png");
        border-radius: 8px 0 0 8px;
        position: absolute;
        height: 100%;
        width: 100%;
        background-size: contain;
        background-repeat: no-repeat;
        mix-blend-mode: multiply;
        z-index: 1;
    }
.marketing-carousel .marketing-carousel-logo-button {
        font-family: ETrade-Bold;
        line-height: 150%;
        color: rgb(29, 40, 48);
        font-size: 1.25rem;

        display: -webkit-inline-box;

        display: -webkit-inline-flex;

        display: -moz-inline-box;

        display: -ms-inline-flexbox;

        display: inline-flex;
        background: rgba(255, 255, 255, 0.15);
        border-radius: 30px;
        -webkit-box-pack: start;
        -webkit-justify-content: flex-start;
           -moz-box-pack: start;
            -ms-flex-pack: start;
                justify-content: flex-start;
        -webkit-box-align: center;
        -webkit-align-items: center;
           -moz-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        padding: 8px 16px;
        position: absolute;
        z-index: 2;
        left: 40px;
        top: 13%;
    }
.marketing-carousel .marketing-carousel-logo-button,
    .marketing-carousel .marketing-carousel-bodytext {
        color: #fff;
    }
@media (max-width: 71.875em) {
        .marketing-carousel .marketing-carousel-body {
            margin-top: 16%;
        }

        .marketing-carousel .marketing-carousel-bodytext {
            font-size: 3vw;
            margin-top: 40px;
        }

            .marketing-carousel .marketing-carousel-bodytext .marketing-carousel-highlight {
                font-size: 4rem;
            }

        .marketing-carousel .marketing-carousel-logo-button {
            font-size: 1rem;
        }

        .marketing-carousel .marketing-carousel-logo {
            height: 26px;
            width: 26px;
        }
}
@supports (mix-blend-mode: multiply) {
        .marketing-carousel .marketing-carousel-overlay {
            background: url("../assets/redesign2020/marketing-carousel/overlay.png");
            background-size: contain;
            background-repeat: no-repeat;
        }
}
@media (max-width: 50em) {
    .marketing-carousel {
        top: 0;
    }

        .marketing-carousel .carousel-indicator-section {
            bottom: 20px;
        }

        .marketing-carousel .marketing-carousel-body {
            padding-top: 24px;
            padding-bottom: 40px;
            padding-left: 48px;
            margin-top: 8px;
            width: 85%;
        }

            .marketing-carousel .marketing-carousel-body .marketing-carousel-highlight {
                font-size: 3.5rem;
            }

            .marketing-carousel .marketing-carousel-body .marketing-carousel-bodytext {
                font-size: 1.25rem;
            }

                .marketing-carousel .marketing-carousel-body .marketing-carousel-bodytext .header-line-1 {
                    width: 100%;
                }

            .marketing-carousel .marketing-carousel-body .button {
                margin: 24px auto 16px;
                padding: 0;
                max-width: 148px;
                height: 40px;
            }

        .marketing-carousel .marketing-carousel-logo-button {
            top: 8%;
        }

        .marketing-carousel *::-ms-backdrop,
        .marketing-carousel .marketing-carousel-bodytext {
            margin-top: 45px;
        }
}
@media (max-width: 40em) {
    .marketing-carousel {
        padding: 0;
    }

        .marketing-carousel .carousel-indicator-arrows {
            display: none;
        }

        .marketing-carousel .marketing-carousel-header {
            font-size: 1.25rem;
            margin-bottom: 16px;
        }

        .marketing-carousel .marketing-carousel-overlay {
            width: calc(100% - 32px);
        }

        .marketing-carousel .marketing-carousel-image-wrapper {
            padding: 0 16px;
        }

            .marketing-carousel .marketing-carousel-image-wrapper::before {
                width: calc(100% - 32px);
            }

            .marketing-carousel .marketing-carousel-image-wrapper::after {
                content: "";
                position: absolute;
                width: 100%;
                height: 40px;
                z-index: -1;
                bottom: 0;
                left: 0;
            }

        .marketing-carousel .marketing-carousel-body-wrapper {
            position: static;
            background: #005cb9;
        }

        .marketing-carousel .marketing-carousel-bodytext {
            width: 100%;
        }

        .marketing-carousel .marketing-carousel-body {
            display: -webkit-box;
            display: -webkit-flex;
            display: -moz-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -webkit-flex-direction: column;
               -moz-box-orient: vertical;
               -moz-box-direction: normal;
                -ms-flex-direction: column;
                    flex-direction: column;
            padding: 0;
            margin: auto auto 16px;
        }

            .marketing-carousel .marketing-carousel-body .button {
                color: rgb(29, 40, 48);
            }

        .marketing-carousel .carousel-indicator-section {
            position: static;
            display: -webkit-box;
            display: -webkit-flex;
            display: -moz-box;
            display: -ms-flexbox;
            display: flex;
            left: 0;
            bottom: 0;
            -webkit-box-pack: center;
            -webkit-justify-content: center;
               -moz-box-pack: center;
                -ms-flex-pack: center;
                    justify-content: center;
            -webkit-box-align: center;
            -webkit-align-items: center;
               -moz-box-align: center;
                -ms-flex-align: center;
                    align-items: center;
            padding-bottom: 16px;
        }

            .marketing-carousel .carousel-indicator-section .carousel-indicators {
                height: 20px;
                width: 20px;
                margin-right: 0;
            }

                .marketing-carousel .carousel-indicator-section .carousel-indicators:not(:first-of-type):not(:last-of-type) {
                    margin: 0 16px;
                }

        .marketing-carousel .marketing-carousel-logo-button {
            top: initial;
            left: 32px;
            bottom: 24px;
        }

        .marketing-carousel *::-ms-backdrop,
        .marketing-carousel .marketing-carousel-logo-button {
            top: 75%;
            max-height: 48px;
        }

        .marketing-carousel *::-ms-backdrop,
        .marketing-carousel .marketing-carousel-bodytext {
            margin-top: 16px;
        }

        .marketing-carousel .marketing-carousel-image-wrapper::after,
        .marketing-carousel .marketing-carousel-body-wrapper,
        .marketing-carousel .carousel-indicator-section {
            background: #1d87f2;
        }
}
@media (max-width: 30em) {
        .marketing-carousel .marketing-carousel-header {
            font-size: 0.75rem;
        }

        .marketing-carousel .marketing-carousel-logo {
            height: 23px;
            width: 23px;
        }

        .marketing-carousel .marketing-carousel-logo-button {
            font-size: 0.75rem;
            left: 24px;
            bottom: 16px;
        }
}
