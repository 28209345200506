/* this section is deprecated - prefer to use device-specific names if possible */
/* device based size names */
/* iPhone 5 width: 20em */
/* iPhone 6 width: */
/* iPhone 5 height: 35.5em */
/* iPhone 6 plus height: 46em */
/* iPad width: 48em */
/* iPad height: 64em */
.desktop {
    display: block;
}
.mobile {
    display: none;
}
@media (max-width: 30em) {
    .desktop {
        display: none;
    }

    .mobile {
        display: block;
    }
}
/**
 * CSS for colors
 */
/* stylelint-disable color-no-hex */
:root {
    /* TIER ONE - DESCRIPTIVE NAMES */

    /* Default / Text color + Light background / Background color + Light text */        /* dark orange */          /* brand blue */           /* red */        /* purple */         /* dark green */          /* gray */            /* white */        /* yellow */

    /* Highlight / Text color + Dark background / Background color + Dark text */                                /* orange */            /* light red #f0425f */      /* light purple #ce51e1 */                                 /* bright green */                                  /* bright blue - brand color */                                /* light yellow */

    /* Alerts */

    /* Additional blues */         /* dark blue #003366 */  /* medium blue #0066cc */ /* light blue #c7e3ff */  /* light blue #e5f2ff */

    /* Additional reds */  /* light red #fbd0d7 */  /* dark red #8E0B21 */

    /* Additional greens */                                 /* dark green */ /* light green #e5fff1 */

    /* Additional purples */    /* extra light purple #f3d3f8 */       /* very light purple #f3d3f840 */

    /* Additional oranges */    /* light orange #ffe8cc */

    /* Grayscale Colors */  /* Near black */ /* Dark gray #1d2830 */  /* Formerly graphite */       /* medium gray */ /* #b1b2b4 */        /* #d8d9da */  /* Near white #f2f2f3 */

    /* TIER TWO - SEMANTIC NAMES */

    /* Product Colors */

    /* Component Colors */

    /* Partner/Refi Lender Colors */

    /* First Republic palette */    /* forest green */        /* gold hacienda */        /* tan gray concrete */      /* dark gray matterhorn */

    /* Visualization palette */

    /* Marketing Page Colors */ /* #1d2830; */ /* #005cb9; *//* #0f1519; */ /* #1d2830; */ /* #5a666e; *//* #8a8c8f; */ /* #b1b2b4; */ /* #d7d8d9; *//* #f2f2f3; */ /* #e5f2ff; */ /* #eddef0; */
}
/**
 * CSS for text, titles, labels. word.
 */
/* Marketing Text Mixins */
/**
 * CSS for laying out and displaying blocks and boxes
 */
:root {

    /* Consistent paddings */

    /* shadows */

    /* All z-index values should be listed here */

    /* widths */
}
.logo-garden {
    width: 100%;
    border-radius: 8px;
    padding: 48px 24px;
    background-color: #fff;
    -webkit-box-shadow: 10px 20px 80px 0 rgba(11, 42, 65, 0.17);
            box-shadow: 10px 20px 80px 0 rgba(11, 42, 65, 0.17);
}
.logo-garden .logo-header {
        font-family: ETrade-Regular;
        line-height: 150%;
        color: rgb(29, 40, 48);
        font-size: 1rem;

        font-family: ETrade-Bold;
        margin-bottom: 16px;
        text-align: center;
    }
.logo-garden .logo-images {
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -webkit-flex-direction: row;
           -moz-box-orient: horizontal;
           -moz-box-direction: normal;
            -ms-flex-direction: row;
                flex-direction: row;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
           -moz-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        -webkit-box-align: center;
        -webkit-align-items: center;
           -moz-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        -webkit-flex-wrap: wrap;
            -ms-flex-wrap: wrap;
                flex-wrap: wrap;
    }
.logo-garden .logo {
        -webkit-box-flex: 0;
        -webkit-flex-grow: 0;
           -moz-box-flex: 0;
            -ms-flex-positive: 0;
                flex-grow: 0;
        -webkit-flex-shrink: 1;
            -ms-flex-negative: 1;
                flex-shrink: 1;
        margin: 16px;
        max-width: 140px;
        max-height: 80px;
    }
@media (max-width: 50em) {
    .logo-garden {
        padding: 24px 0 16px;
    }

        .logo-garden .logo {
            max-width: 90px;
            max-height: 60px;
        }
}
@media (max-width: 40em) {
    .logo-garden {
        padding: 40px 0 32px;
    }

        .logo-garden .logo {
            max-width: 70px;
            max-height: 50px;
            margin: 16px 8px;
        }

            .logo-garden .logo:nth-child(6n) ~ * {
                display: none;
            }
}
