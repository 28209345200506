/* this section is deprecated - prefer to use device-specific names if possible */
/* device based size names */
/* iPhone 5 width: 20em */
/* iPhone 6 width: */
/* iPhone 5 height: 35.5em */
/* iPhone 6 plus height: 46em */
/* iPad width: 48em */
/* iPad height: 64em */
.desktop {
    display: block;
}
.mobile {
    display: none;
}
@media (max-width: 30em) {
    .desktop {
        display: none;
    }

    .mobile {
        display: block;
    }
}
/**
 * CSS for colors
 */
/* stylelint-disable color-no-hex */
:root {
    /* TIER ONE - DESCRIPTIVE NAMES */

    /* Default / Text color + Light background / Background color + Light text */        /* dark orange */          /* brand blue */           /* red */        /* purple */         /* dark green */          /* gray */            /* white */        /* yellow */

    /* Highlight / Text color + Dark background / Background color + Dark text */                                /* orange */            /* light red #f0425f */      /* light purple #ce51e1 */                                 /* bright green */                                  /* bright blue - brand color */                                /* light yellow */

    /* Alerts */

    /* Additional blues */         /* dark blue #003366 */  /* medium blue #0066cc */ /* light blue #c7e3ff */  /* light blue #e5f2ff */

    /* Additional reds */  /* light red #fbd0d7 */  /* dark red #8E0B21 */

    /* Additional greens */                                 /* dark green */ /* light green #e5fff1 */

    /* Additional purples */    /* extra light purple #f3d3f8 */       /* very light purple #f3d3f840 */

    /* Additional oranges */    /* light orange #ffe8cc */

    /* Grayscale Colors */  /* Near black */ /* Dark gray #1d2830 */  /* Formerly graphite */       /* medium gray */ /* #b1b2b4 */        /* #d8d9da */  /* Near white #f2f2f3 */

    /* TIER TWO - SEMANTIC NAMES */

    /* Product Colors */

    /* Component Colors */

    /* Partner/Refi Lender Colors */

    /* First Republic palette */    /* forest green */        /* gold hacienda */        /* tan gray concrete */      /* dark gray matterhorn */

    /* Visualization palette */

    /* Marketing Page Colors */ /* #1d2830; */ /* #005cb9; *//* #0f1519; */ /* #1d2830; */ /* #5a666e; *//* #8a8c8f; */ /* #b1b2b4; */ /* #d7d8d9; *//* #f2f2f3; */ /* #e5f2ff; */ /* #eddef0; */
}
/**
 * CSS for text, titles, labels. word.
 */
/* Marketing Text Mixins */
/**
 * CSS for laying out and displaying blocks and boxes
 */
:root {

    /* Consistent paddings */

    /* shadows */

    /* All z-index values should be listed here */

    /* widths */
}
/* basic setup for triangles in :before and :after pseudo-elements */
/* clears ul/ol defaults */
/* TODO Deprecated */
/* best pronounced with a texan drawl */
/* is this annoying yet? */
/* Marketing Button Mixins */
.modal-video-launcher-card {
    display: -ms-grid;
    display: grid;
        grid-template-areas:
        "preview-image body"
        "logo          body";
    -ms-grid-columns: 55% 1fr;
    grid-template-columns: 55% 1fr;
    color: #fff;
    margin: 56px auto 0;
    padding: 0 16px;
    max-width: 1180px;
}
.modal-video-launcher-card .preview-image-wrapper {
        -ms-grid-row: 1;
        -ms-grid-column: 1;
        cursor: pointer;
        grid-area: preview-image;
        color: #fff;
        width: 100%;
    }
.modal-video-launcher-card .preview-image-wrapper::before {
            content: " ";
            position: absolute;
            width: 100%;
            height: calc(100% - 32px);
            bottom: 0;
            background: rgb(29, 40, 48);
            margin: 32px 0 0 32px;
            z-index: -1;
        }
.modal-video-launcher-card .preview-image {
        width: 100%;
        display: block;
    }
.modal-video-launcher-card .logo-wrapper {
        -ms-grid-row: 2;
        -ms-grid-column: 1;
        grid-area: logo;
        background: rgb(29, 40, 48);
        border-bottom-left-radius: 16px;
        margin-left: 32px;
        border: 1px solid rgb(29, 40, 48);
    }
.modal-video-launcher-card .logo {
        display: block;
        margin: 24px auto;
        max-width: 300px;
    }
.modal-video-launcher-card .marketing-video-body {
        -ms-grid-row: 1;
        -ms-grid-row-span: 2;
        -ms-grid-column: 2;
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
           -moz-box-orient: vertical;
           -moz-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        grid-area: body;
        background: rgb(29, 40, 48);
        border-radius: 0 16px 16px 0;
        margin-top: 32px;
        padding: 32px;
        border: 1px solid rgb(29, 40, 48);
    }
.modal-video-launcher-card .marketing-video-body .marketing-video-header {
            font-family: ETrade-Bold;
            line-height: 100%;
            color: rgb(29, 40, 48);
            font-size: 2rem;
            line-height: 120%;

            margin: auto 0 32px;
            color: #fff;
        }
.modal-video-launcher-card .marketing-video-body .marketing-video-header .highlighted {
                color: #00a0df;
            }
.modal-video-launcher-card .marketing-video-body .marketing-video-bodytext {
            font-family: ETrade-Regular;
            line-height: 150%;
            color: rgb(29, 40, 48);
            font-size: 1rem;

            color: #fff;
        }
.modal-video-launcher-card .marketing-video-body .button {
            border-radius: 32px;
            font-size: 0.75rem;
            font-weight: 600;
            text-align: center;
            font-family: ETrade-Bold;
            line-height: 150%;
            color: rgb(29, 40, 48);
            display: -webkit-inline-box;
            display: -webkit-inline-flex;
            display: -moz-inline-box;
            display: -ms-inline-flexbox;
            display: inline-flex;
            -webkit-box-pack: center;
            -webkit-justify-content: center;
               -moz-box-pack: center;
                -ms-flex-pack: center;
                    justify-content: center;
            -webkit-box-align: center;
            -webkit-align-items: center;
               -moz-box-align: center;
                -ms-flex-align: center;
                    align-items: center;

            height: 48px;
            max-width: 200px;
            cursor: pointer;
            display: -webkit-box;
            display: -webkit-flex;
            display: -moz-box;
            display: -ms-flexbox;
            display: flex;
            justify-content: center;
            margin: 32px auto auto 0;
            padding: 8px 24px;
            font-size: 0.625rem;
            color: #fff;
            text-transform: uppercase;
            line-height: 1.2rem;
            letter-spacing: 2px;
        }
.modal-video-launcher-card .marketing-video-body .button .play-button {
                height: 20px;
                width: 20px;
                margin-right: 8px;
            }
@media (max-width: 50em) {
    .modal-video-launcher-card {
            grid-template-areas:
            "preview-image"
            "logo"
            "body";
        -ms-grid-columns: 1fr;
        grid-template-columns: 1fr;
        padding: 0 16px;
    }

        .modal-video-launcher-card .preview-image-wrapper {
            width: 550px;
            margin: auto;
            border-bottom: 1px solid rgb(29, 40, 48);
        }

            .modal-video-launcher-card .preview-image-wrapper::before {
                display: none;
            }

            .modal-video-launcher-card .preview-image-wrapper::after {
                content: " ";
                display: block;
                position: absolute;
                height: 50px;
                width: 100%;
                background: rgb(29, 40, 48);
                bottom: 0;
                border-radius: 16px 16px 0 0;
                z-index: -1;
            }

        .modal-video-launcher-card .preview-image {
            width: 490px;
            margin: 0 auto;
        }

        .modal-video-launcher-card .marketing-video-body,
        .modal-video-launcher-card .logo-wrapper {
            margin: 0 auto;
            width: 550px;
            border-radius: 0;
        }

        .modal-video-launcher-card .marketing-video-body {
            padding-top: 8px;
            border-radius: 0 0 16px 16px;
            text-align: center;
            display: -webkit-box;
            display: -webkit-flex;
            display: -moz-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -webkit-flex-direction: column;
               -moz-box-orient: vertical;
               -moz-box-direction: normal;
                -ms-flex-direction: column;
                    flex-direction: column;
        }

            .modal-video-launcher-card .marketing-video-body .button {
                margin-left: auto;
                margin-right: auto;
                -webkit-align-self: center;
                    -ms-flex-item-align: center;
                            -ms-grid-row-align: center;
                        align-self: center;
            }

        .modal-video-launcher-card .logo {
            margin-bottom: 24px;
        }
    .modal-video-launcher-card .preview-image-wrapper {
        -ms-grid-row: 1;
        -ms-grid-column: 1;
    }
    .modal-video-launcher-card .logo-wrapper {
        -ms-grid-row: 2;
        -ms-grid-column: 1;
    }
    .modal-video-launcher-card .marketing-video-body {
        -ms-grid-row: 3;
        -ms-grid-row-span: 1;
        -ms-grid-column: 1;
    }
}
@media (max-width: 40em) {
    .modal-video-launcher-card {
        display: block;
        padding: 0;
    }

        .modal-video-launcher-card .preview-image,
        .modal-video-launcher-card .preview-image-wrapper {
            width: 100%;
        }

        .modal-video-launcher-card .marketing-video-body,
        .modal-video-launcher-card .logo-wrapper {
            width: auto;
            margin: 0 16px;
        }

            .modal-video-launcher-card .marketing-video-body .logo, .modal-video-launcher-card .logo-wrapper .logo {
                max-width: 275px;
            }

        .modal-video-launcher-card .marketing-video-body .button {
            line-height: 1.5rem;
            max-width: 220px;
            -webkit-box-align: center;
            -webkit-align-items: center;
               -moz-box-align: center;
                -ms-flex-align: center;
                    align-items: center;
            margin: 40px auto 24px;
        }

            .modal-video-launcher-card .marketing-video-body .button .play-button {
                bottom: 2px;
            }
}
